@import url("~leaflet/dist/leaflet.css");

.Toastify__toast {
  font-size: 15px;
}

.Toastify__toast-theme--dark {
  background-color: #1f283e;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f6f9;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#map {
  height: 300px;
}
