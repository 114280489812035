.tabs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 0.5rem;
  margin: 2rem 0;
  max-height: 400px;
  overflow-y: auto;
}

.tab-button {
  background-color: #ececec;
  border-width: 0px;
  color: #344767;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active {
  background-color: #aaa;
  color: #fff;
}

.completed {
  border-width: 3px;
  border-color: chartreuse;
}

@media (max-width: 768px) {
  .tabs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
}
